.crm-calendar-marker {
  background-color: @primary-color;

  &::before {
    top: -4px;
    width: 10px;
    height: 10px;
    border: 0;
    border-radius: 100%;
    background-color: @primary-color;
  }
}

.crm-calendar-resource-label {
  display: flex;
  align-items: center;
  min-width: 0;
  cursor: default;

  .ant-avatar {
    margin-right: @margin-1;
  }

  .ehr-text {
    min-width: 0;

    .ant-typography {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.crm-calendar-day-multiple {
  .crm-calendar-resource-label {
    * {
      line-height: 16px;
    }

    .ant-avatar {
      width: 16px;
      height: 16px;
      font-size: 8px;
    }
  }
}

.crm-calendar-sider-minute {
  display: flex;
  align-items: center;
  color: @text-color-secondary;
  font-size: @font-size-sm;
}
