.simple-divider-horizontal {
  margin: 0 0 8px;

  &::before,
  &::after {
    content: none;
  }

  .ant-divider-inner-text {
    padding: 0;
  }
}

.divider-horizontal-margin-1 {
  margin: @margin-1 0;
}
