.ehr-form-page {
  display: flex;
  flex-direction: column;
}

.ehr-form-page-group {
  display: block;
  margin-bottom: @margin-4;

  &__item:not(:last-child) > .ehr-form-control {
    margin-bottom: @margin-4;
  }

  &__item__title--required {
    .ant-typography::after {
      margin-left: @margin-1;
      color: @red-base;
      content: '*';
    }
  }
}
