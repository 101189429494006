.ehr-questionnaire {
  display: flex;
  max-height: 100%;
  overflow: hidden;

  &__layout {
    min-height: 100%;
    overflow: hidden;

    .ant-layout-header {
      display: flex;
      position: sticky;
      z-index: 10;
      top: 0;
      align-items: center;
      height: 56px;
      padding: 0 @padding-2;
      border-bottom: 1px solid @border-color-base;
      background-color: #ffffff;
      line-height: 1.5;

      .ehr-title {
        flex: unset;
      }

      .ant-divider {
        height: 28px;
        margin: 0 @margin-2;
      }
    }
  }

  &__content {
    overflow: auto;

    .crm-loading {
      align-self: center;
    }
  }

  &__back {
    margin-right: @margin-2;

    .anticon {
      position: relative;
      top: 1px;
    }
  }

  &__actions {
    margin-left: auto;
  }

  &__status {
    display: inline-flex;
    align-items: center;

    .anticon {
      margin-left: @margin-1;
    }
  }
}
