.switch-with-text {
  display: flex;
  align-items: center;
  color: @text-title-color;

  nz-switch {
    margin-right: 8px;
    line-height: 1;
  }
}
