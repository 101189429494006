.ehr-admission-diagnoses {
  &__control {
    display: flex;
    align-items: center;
  }

  &__select {
    flex: 1;
    min-width: 0;
  }
}
