.ehr-calendar-ab {
  display: flex;
  align-items: center;
  padding: @padding-4;

  &__range,
  &__period {
    margin-right: @margin-4;
  }

  &__range--date {
    min-width: 150px;
  }

  &__range--week {
    min-width: 260px;
  }

  &__period {
    display: grid;
    gap: @padding-1;
    grid-template-columns: 32px 1fr 32px;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: @height-base;
      padding: 0 @padding-2;
      border: @border-width-base @border-style-base @border-color-base;
      border-radius: @border-radius-base;
      cursor: pointer;

      &:hover {
        background-color: @bg-light-color;
      }
    }
  }

  &__actions {
    margin-left: auto;
  }
}
