@table-header-color: @text-color;

.ant-table {
  &.ant-table-bordered {
    > .ant-table-container {
      border-top: 1px solid @table-border-color;

      > .ant-table-content > table {
        border-top: 0;
      }
    }
  }
}

.ant-table-thead > tr > th {
  padding: 12px 16px;
  background-color: #fafafa;

  &.ant-table-cell::before {
    content: none !important;
  }

  &.ant-table-column-has-sorters:hover {
    background-color: #fafafa;
  }
}

.table-nowrap-header {
  .ant-table {
    .ant-table-thead {
      .ant-table-cell {
        white-space: nowrap;
      }
    }
  }
}

.table-nowrap-body {
  .ant-table {
    .ant-table-tbody {
      .ant-table-cell {
        white-space: nowrap;
      }
    }
  }
}
