@import '../../../../styles';

.ehr-editable-form {
  display: block;
  position: relative;

  &-wrapper {
    display: block;

    .ant-card {
      &-head {
        min-height: 0;
        padding: 0;
        border-bottom: 0;
        font-size: 20px;
        font-weight: 500;

        &-wrapper {
          align-items: flex-start;
        }

        &-title {
          padding: 0 0 16px;
        }
      }

      &-body {
        padding-bottom: 0;
      }
    }
  }

  &__form {
    margin: -@margin-3 -@margin-3 @margin-4;
    padding: @padding-3 @padding-3 0;
    transition: background-color 0.5s;
    border-radius: @border-radius-base;
  }

  &__action {
    display: none;
    position: absolute;
    top: @padding-3;
    right: 0;
  }

  &--edit,
  &--editable:hover {
    .ehr-editable-form {
      &__form {
        background-color: @bg-light-color;
      }

      &__action {
        display: block;
      }
    }
  }

  .ant-divider {
    .simple-divider-horizontal;
    font-weight: bold;
  }

  .ehr-label-value {
    &__label {
      margin-bottom: 2px;
      line-height: 24px;
    }

    &__value {
      line-height: 24px;
    }
  }
}
