.ehr-ut {
  &-event + &-event {
    margin-top: @margin-2;
    padding-top: @padding-2;
    border-top: @border-width-base @border-style-base @border-color-base;
  }

  &-event-tag {
    display: block;
    padding: 2px @padding-1;
    border-radius: @border-radius-base;

    .anticon {
      margin-right: 4px;
    }

    &.blue {
      background-color: #f0f5ff;
      color: #030852;
    }

    &.green {
      background-color: #f1ffe2;
      color: #092b00;
    }

    &.turquoise {
      background-color: #e6fffb;
      color: #002329;
    }

    &.brown {
      background-color: #fff2e8;
      color: #610b00;
    }

    &.yellow {
      background-color: #feffe6;
      color: #614700;
    }

    &.gray {
      background-color: @background-color-light;
      color: @heading-color;
    }

    .ant-typography {
      color: inherit;
    }
  }

  &-event-header {
    display: flex;
    align-items: center;
  }

  &-timestamp {
    flex: 1;
    color: #a6a6a6;
  }

  &-load-more {
    margin-top: 32px;
    text-align: center;
  }
}
