@import 'ant/index';

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;

  &::after {
    right: unset;
    left: 0;
    border-right: 2px solid @white-color;
  }
}

nz-breadcrumb-item:last-child .ant-breadcrumb-separator {
  display: inline-block;
}

.ant-col {
  min-height: 0;
}

.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
}

.ant-drawer-header-title {
  .ant-drawer-close {
    order: 2;
    margin-right: 0;
  }

  .ant-drawer-title {
    order: 1;
  }
}

.ant-tag-default {
  background-color: @bg-light-color;
}
