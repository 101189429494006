@import '../../../../styles';

.ehr-editable-card {
  .ant-card {
    &-head {
      min-height: 0;
      padding: 0;
      border-bottom: 0;
      color: @text-title-color;
      font-size: 20px;
      font-weight: 500;

      &-wrapper {
        align-items: flex-start;
      }

      &-title {
        display: flex;
        align-items: center;
        padding: 0 0 @padding-2;
      }
    }

    &-body {
      padding-bottom: 0;
    }

    &-extra {
      display: none;
      padding: 0;
    }
  }

  &--edit,
  &:hover {
    .ant-card {
      &-extra {
        display: block;
      }
    }
  }

  &__tags {
    .ant-tag {
      margin-left: @margin-2;
      font-weight: normal;
    }
  }

  .ant-divider {
    .simple-divider-horizontal;
    color: @text-color;
    font-weight: bold;
  }

  .ehr-label-value {
    &__label {
      margin-bottom: 2px;
      line-height: 24px;
    }

    &__value {
      line-height: 24px;
    }
  }
}
