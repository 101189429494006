.ehr-form,
.ehr-form-score {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 32px auto;
  background-color: #ffffff;
}

.ehr-form {
  &__header {
    display: flex;
    position: sticky;
    z-index: 9;
    top: 0;
    padding: @padding-2 @padding-4;
    border-bottom: 1px solid @border-color-base;
    background-color: #ffffff;

    &__title {
      flex: 1;
    }
  }

  &__body {
    padding: @padding-2 @padding-4;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: 0 @padding-4 @padding-2;
  }

  &-error {
    margin-top: @margin-1;
    color: @red-base;
    font-size: @font-size-base;
  }
}

.ehr-form-score {
  padding: @padding-4;

  &__wrapper {
    border: 1px solid @border-color-base;
  }

  &__result {
    padding: @padding-4;
    background-color: @background-color-light;
  }

  &__instructions {
    padding: @padding-4;
  }

  &__instruction {
    &--margin-top {
      margin-top: @margin-2;
    }

    &__children {
      &--padded {
        padding-left: @padding-3;
      }
    }
  }

  &__results {
    margin-top: @margin-2;
  }

  &__scores {
    display: flex;
    flex-wrap: wrap;
  }

  &__score:not(:last-child) {
    margin-right: @margin-1;
  }
}
