.ehr-todos {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;

  &__header {
    display: flex;
    flex-wrap: wrap;
    padding: @padding-2;

    &__user {
      display: flex;
      flex: 100%;
      align-items: center;
      margin-top: @margin-3;
      gap: @padding-1;
      min-width: 0;
    }

    &__option {
      display: inline-flex;
      align-items: center;
      height: 28px;
      min-width: 0;
      padding: 4px @padding-1;
      border: 1px solid @secondary-color;
      border-radius: 16px;
      background-color: @bg-light-color;
      font-size: @font-size-sm;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      > span {
        flex: 1;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &--user {
        app-avatar {
          display: flex;
          margin-right: @margin-1;
        }
      }

      &--all {
        min-width: 80px;
      }

      &--selected {
        background-color: @secondary-color;
        border-color: @primary-color;
        color: @primary-color;
      }
    }
  }

  &__list {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 0;
    margin-top: @margin-1;
    overflow: auto;
  }
}

.ehr-todo {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: @padding-2;
  border-radius: @border-radius-base;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: @border-width-base @border-style-base @border-color-base;
  }

  &:hover {
    background-color: @bg-light-color;

    .ehr-todo__action {
      display: block;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: @margin-1;
  }

  &__footer {
    display: flex;
    align-items: flex-end;
    min-width: 0;
    min-height: 28px;
    margin-top: 4px;
  }

  &__title {
    .ant-typography {
      color: @text-title-color;
    }
  }

  &__user {
    display: flex;
    flex: 1;
    align-items: center;
    min-width: 0;

    .ant-avatar {
      margin-right: 4px;
    }

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: @font-size-sm;
    }
  }

  &__category {
    flex: 1;
    min-width: 0;
    font-size: @font-size-sm;
  }

  &__expiration {
    display: flex;

    .anticon {
      margin-right: 4px;
    }

    &--overdue {
      .anticon,
      .ant-typography {
        color: @error-color;
      }
    }
  }

  &__actions {
    margin-left: @margin-1;
  }

  &__action {
    display: none;
  }
}

.ehr-todo--success {
  align-items: center;
  padding: @padding-3;
  cursor: default;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-bottom: @margin-3;
    border-radius: 100%;
    background-color: @success-color-deprecated-bg;
    color: @success-color;
    font-size: 28px;
  }

  .ehr-text {
    text-align: center;
  }
}
