.form-wrapper {
  height: 100%;
  overflow: auto;
}

.form-signature {
  display: block;
  padding: 32px;

  &-title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 500;
  }

  &-text {
    margin-bottom: 64px;
    color: #595959;
  }
}

.form-body {
  max-width: 792px;
  margin: 32px auto;
  padding: 0 32px 16px;
  border-radius: 4px;
  background-color: #ffffff;

  &-header {
    display: flex;
    position: sticky;
    z-index: 10;
    top: 56px;
    align-items: center;
    margin: 0 -32px 16px;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #ffffff;
    color: #8c8c8c;
  }

  &-title {
    flex: 1;
  }
}

.form-section {
  margin-bottom: 32px;

  &-category {
    margin-bottom: 24px;
    color: #992c0b;
    font-weight: 500;
  }
}

.form-header {
  display: flex;
  position: sticky;
  z-index: 10;
  top: 0;
  align-items: center;
  height: 56px;
  padding: 8px 16px;
  border-bottom: 1px solid #e8e8e8;
  background-color: #ffffff;

  &-back {
    .anticon {
      position: relative;
      top: 1px;
    }
  }

  &-title {
    margin-left: 16px;
    color: #262626;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }

  &-divider {
    margin-right: 16px;
    padding-right: 16px;
    border-right: 1px solid #ebebeb;
  }

  &-user,
  &-organization {
    color: #595959;
    line-height: 28px;
  }

  &-autosave {
    margin-left: @margin-1;
  }

  &-actions {
    margin-left: auto;
  }
}

.form-title {
  margin-bottom: 16px;
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;

  &-xl,
  &-h1 {
    margin-bottom: 64px;
    font-size: 30px;
    line-height: 38px;
  }

  &-h2 {
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 32px;
  }

  &-h3 {
    margin-bottom: 32px;
    font-size: 20px;
    line-height: 28px;
  }

  &-h4 {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  &-h5 {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 22px;
  }

  &-mb-05 {
    margin-bottom: 4px;
  }

  &-mb-5 {
    margin-bottom: 40px;
  }

  &-description {
    margin-bottom: 8px;
    color: #8c8c8c;

    &-mb-5 {
      margin-bottom: 40px;
    }
  }
}

.form-subtitle {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 700;

  &__info {
    color: #8c8c8c;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}

.form-addable-section {
  padding-bottom: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
    border-bottom: 1px solid #e8e8e8;
  }
}

.form-add-another {
  color: #0d00bf;
  font-weight: 500;
  cursor: pointer;
}

.form-divider {
  margin: 0 -32px 32px;
  border-bottom: 1px solid #e8e8e8;
}

.form-opened-section {
  margin-top: 8px;
  padding: 16px;
  border-radius: 8px;
  background-color: @bg-light-color;
}

.form-subsection-title {
  margin-top: 32px;
  margin-bottom: 8px;
  font-weight: bold;
}

.form-control-label {
  margin-bottom: 8px;
  color: #8c8c8c;
  font-size: 12px;

  &-level-2 {
    color: @text-color;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
  }

  &-level-3 {
    margin-bottom: 16px;
    color: @text-color;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  &-level-4 {
    margin-bottom: 32px;
    color: @text-color;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  &-level-5 {
    margin-bottom: 64px;
    color: @text-color;
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
  }
}

.form-control-description {
  margin-top: 8px;
  margin-bottom: 8px;
  color: #8c8c8c;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
}

.form-text-value {
  white-space: pre-line;
}

.form-bg-level-1 {
  padding: 16px;
  border-radius: 8px;
  background-color: @bg-light-color;
}

.form-bg-level-2 {
  padding: 16px;
  border-radius: 8px;
  background-color: @bg-light-color-2;
}

.form-deletable-row {
  display: flex;
  align-items: center;

  &-body {
    flex: 1;
  }

  &-delete {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;

    i {
      color: #f5222d;
      font-size: 24px;
      cursor: pointer;
    }
  }
}

.forms-score-wrapper {
  max-width: 792px;
  margin: 0 auto 32px;
  padding: 32px;
  background-color: #ffffff;
}

.forms-score {
  border: 1px solid #e8e8e8;
}

.forms-score-content {
  padding: 32px;
  color: #8c8c8c;
}

.forms-score-content-title {
  margin-bottom: 8px;
  color: @text-color;
  font-size: 16px;
  font-weight: bold;
}

.form-array-group {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &-label {
    margin-bottom: 16px;
  }

  &-inner {
    flex: 1;
  }

  &-remove {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;

    i {
      color: #f5222d;
      font-size: 24px;
      cursor: pointer;
    }
  }
}

.form-array-add-another {
  color: #0d00bf;
  font-weight: 500;
  cursor: pointer;
}
