.ehr-text {
  .ant-typography {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;

    &-copy {
      font-size: @font-size-lg;

      &:not(.ant-typography-copy-success) {
        color: @text-color;
      }
    }
  }

  nz-text-copy {
    line-height: 16px;
  }

  &--weight {
    &-medium {
      font-weight: 500;
    }

    &-bold {
      font-weight: 700;
    }
  }

  &--size {
    &-x-small {
      font-size: @font-size-sm - 2px;
      line-height: 12px;
    }

    &-small {
      font-size: @font-size-sm;
      line-height: 20px;
    }

    &-medium {
      font-size: @font-size-base;
      line-height: 20px;
    }

    &-large {
      font-size: @font-size-lg;
      line-height: 22px;
    }
  }

  &--margin {
    &-1 {
      .ant-typography {
        margin-bottom: @margin-1;
      }
    }

    &-2 {
      .ant-typography {
        margin-bottom: @margin-2;
      }
    }

    &-3 {
      .ant-typography {
        margin-bottom: @margin-3;
      }
    }

    &-4 {
      .ant-typography {
        margin-bottom: @margin-4;
      }
    }
  }

  &--text {
    display: inline-block;

    .ant-typography {
      display: inline-flex;
    }
  }

  &--paragraph {
    display: block;

    .ant-typography {
      display: flex;
    }
  }
}
