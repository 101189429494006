.flex-layout {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.centered {
  .flex-layout;
  .align-center;
  .justify-center;
}

.centered-column {
  .centered;
  flex-direction: column;
}
