@crm-calendar-header-height: 56px;
@crm-calendar-day-height: 36px;

.ehr-calendar {
  .ant-layout {
    height: 100%;
    background-color: #ffffff;

    &-sider {
      border-right: @border-width-base @border-style-base @border-color-base;
    }

    &-content {
      display: flex;
      flex-direction: column;

      crm-calendar {
        flex: 1;
        min-height: 0;
      }

      .crm-calendar {
        * {
          border-color: @border-color-base;
        }

        &-wrapper {
          border: 0;
        }
      }
    }
  }
}
