.ehr-date-range {
  display: block;

  &__presets {
    .ant-picker-panel-container {
      display: flex;

      .ant-picker-footer {
        order: 1;

        .ant-picker-preset {
          .ant-tag {
            padding: 0;
            margin: 0;
            font-size: @font-size-base;
            color: @text-color;
            background-color: transparent;
          }
        }
      }

      .ant-picker-panels {
        order: 2;
        border-left: 1px solid @border-color-base;

        .ant-picker-panel {
          border-bottom: 0;
        }
      }
    }
  }
}
