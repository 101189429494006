.crm-dropdown-button__menu {
  &__item {
    .crm-action-button {
      padding: 0;

      .ant-btn-text:not(.ant-btn-dangerous) {
        color: @text-color;
      }
    }
  }
}
