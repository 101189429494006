.crm-calendar-resource-event-container {
  padding: 4px;
}

.ehr-calendar-event {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: @padding-1;
  overflow: hidden;
  border-radius: @border-radius-base;
  cursor: pointer;

  &__range {
    margin-top: auto;
  }

  &__tooltip {
    &__name {
      display: flex;
      align-items: center;

      .ant-avatar {
        margin-right: @margin-1;
      }
    }
  }

  &--visit {
    background-color: @secondary-color;
    box-shadow: inset 0 3px @primary-color;
  }
}
