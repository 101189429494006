.user-detail-card {
  .ant-card-head {
    min-height: 0;
    padding: 0;
    border-bottom: 0;
    color: @text-color;
    font-size: 20px;
    font-weight: 500;
  }

  .ant-card-head-wrapper {
    align-items: flex-start;
  }

  .ant-card-head-title {
    padding: 0 0 16px;
  }

  .ant-card-body {
    padding: 0;
  }

  .ant-card-extra {
    padding: 0;
  }
}

.user-detail-card-simple {
  > .ant-card-body {
    padding: 24px 0 0 24px;
  }

  > .ant-card-head {
    .ant-card-extra {
      display: none;
    }
  }

  &:hover >,
  &.editing > {
    .ant-card-extra {
      display: block;
    }
  }
}
