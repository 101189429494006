@import '../../../styles';

.grid {
  display: grid;
}

.grid--base-gap {
  gap: @padding-1;
}

.grid--medium-gap {
  gap: @padding-2;
}

.grid--two-columns {
  grid-template-columns: 1fr 1fr;
}

.grid--four-columns {
  grid-template-columns: repeat(4, 1fr);
}

.grid--two-columns__item--left {
  grid-column-start: 1/2;
}

.grid--two-columns__item-right {
  grid-column-start: 2/3;
}
