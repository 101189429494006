@import '../../../../styles';

.ehr-label-value {
  display: flex;

  &--column {
    flex-direction: column;
    width: 100%;
  }

  &:not(:last-child) {
    margin-bottom: @margin-1;
  }

  &__label {
    margin-right: @margin-1;
    color: @text-color-secondary;
    font-size: @font-size-sm;
    line-height: 20px;
  }

  &__value {
    flex: 1;
    color: @text-color;
    font-size: @font-size-base;
    line-height: 20px;
  }
}
