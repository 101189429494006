/* You can add global styles to this file, and also import other style files */
@import '../node_modules/ng-zorro-antd/ng-zorro-antd.less';
@import '../node_modules/common-module/common-module.less';

// https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@dark-primary-bg: linear-gradient(0deg, rgba(13, 0, 191, 0.15), rgba(13, 0, 191, 0.15)), #121212;

@background-color-light: @bg-light-color;

@dropdown-line-height: @line-height-base;

@text-color: @text-default-color;
@text-color-secondary: @text-secondary-color;

@heading-color: @text-title-color;
@heading-4-size: 16px;

@input-placeholder-color: @text-placeholder-color;
@input-icon-color: @input-placeholder-color;
@input-height-lg: 40px;

@border-color-base: @border-color;
@border-radius-base: 4px;

@layout-sider-background: @dark-primary-bg;
@layout-body-background: @bg-light-color;

@menu-dark-bg: inherit;

@btn-font-size-lg: @font-size-base;

@table-header-bg: @white-color;
@table-padding-vertical: 8px;

@label-color: @text-secondary-color;
@form-item-label-font-size: @font-size-sm;
@form-vertical-label-padding: 0 0 4px;
@form-item-label-height: 20px;

@avatar-font-size-sm: @font-size-sm;
@avatar-bg: @secondary-color;
@avatar-color: @primary-color;

@tabs-horizontal-gutter: @margin-2;
@tabs-horizontal-padding-lg: @padding-1 @padding-2;

.ehr-compare-container {
  position: fixed;
  z-index: -1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

body {
  font-family: Roboto,Open Sans,sans-serif;
}

@import 'assets/styles/generic/index';
@import 'assets/styles/components/index';
@import 'assets/styles/override/index';
