.ehr-options-selector {
  &__options {
    display: block;
    max-height: 260px;
    margin-top: @margin-2;
    overflow-y: scroll;
  }

  &__option {
    display: flex;
    padding: @padding-1 @padding-2;
    border-radius: @border-radius-base;

    .ant-checkbox {
      top: 4px;
    }

    &::after {
      content: none;
    }

    &:hover {
      background-color: @bg-light-color;
    }

    &.ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0;
    }

    > span:nth-child(2) {
      display: flex;
      flex: 1;
      align-items: center;
      min-width: 0;
      margin-left: 8px;
      padding: 0;

      .ant-tag {
        margin-right: 0;
        margin-left: auto;
      }
    }

    &.ant-checkbox-wrapper-checked {
      background-color: @secondary-color;
    }
  }
}
