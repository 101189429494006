.ehr-user-forms {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    display: flex;
    position: sticky;
    z-index: 3;
    top: -@margin-4;
    align-items: center;
    margin-top: -@margin-4;
    padding-top: @padding-4;
    padding-bottom: @padding-2;
    overflow: hidden;
    background-color: #ffffff;
  }

  &__content {
    flex: 1;
    min-height: 0;
  }

  &-header-actions {
    button:not(:last-child) {
      margin-right: @margin-2;
    }
  }
}
