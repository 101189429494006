.ehr-form-control,
.ehr-form-boolean,
.ehr-form-text,
.ehr-form-date,
.ehr-form-time,
.ehr-form-select {
  display: block;
}

.ehr-form-object {
  display: block;

  &__bg {
    border-radius: @border-radius-base;

    &-level-1 {
      padding: @padding-2;
      background-color: @bg-light-color;
    }

    &-level-2 {
      padding: @padding-2;
      background-color: @bg-light-color-2;
    }
  }

  &__margin {
    &-1 {
      margin-bottom: @margin-1;
    }

    &-2 {
      margin-bottom: @margin-2;
    }

    &-3 {
      margin-bottom: @margin-3;
    }

    &-4 {
      margin-bottom: @margin-4;
    }

    &-5 {
      margin-bottom: @margin-5;
    }
  }
}

.ehr-form-array {
  display: block;

  &__item {
    display: flex;
    flex-wrap: wrap;

    &__label {
      width: 100%;
    }

    &__content {
      display: flex;
      width: 100%;

      & > .ehr-form-control {
        flex: 1;
        min-width: 0;
        border-radius: @border-radius-base;
      }
    }

    &__remove {
      padding-top: @padding-4;
      margin-left: @margin-1;

      .anticon {
        font-size: 24px;
      }
    }

    + .ehr-form-array__item {
      margin-top: @margin-2;
    }
  }

  &__add {
    margin-top: @margin-1;
    padding: 0;
    font-weight: 500;
  }
}

.ehr-form-radio {
  display: block;

  &__wrapper {
    width: 100%;
  }

  &__wrapper--inline {
    display: flex;
  }

  &__option {
    padding: 2px 0;

    .ant-radio-wrapper {
      display: flex;
      align-items: center;
      align-self: flex-start;
      min-height: 40px;

      .ant-radio {
        top: 0;
      }
    }

    &__control {
      padding-left: @padding-3;
    }

    &--right {
      display: flex;

      .ehr-form-radio__option__control {
        flex: 1;
        margin-top: 0;
        padding-left: 0;
      }
    }
  }
}

.ehr-form-checkbox {
  display: block;

  &--bg {
    border-radius: @border-radius-base;

    &-level-1 {
      padding: @padding-2;
      background-color: @bg-light-color;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    &--inline {
      flex-direction: row;
    }
  }

  &__option {
    padding: 2px 0;

    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
      align-self: flex-start;
      min-height: 40px;

      .ant-checkbox {
        top: 0;
      }
    }

    &--right {
      display: flex;

      .ehr-form-checkbox__option__control {
        flex: 1;
        margin-top: 0;
        padding-left: 0;
      }
    }

    &__control {
      padding-left: @padding-3;
    }
  }
}
