@padding: 8px;

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: @padding * 1;
}

.pr-2 {
  padding-right: @padding * 2;
}

.pr-3 {
  padding-right: @padding * 3;
}

.pr-4 {
  padding-right: @padding * 4;
}

.pr-5 {
  padding-right: @padding * 5;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: @padding * 1;
}

.pb-2 {
  padding-bottom: @padding * 2;
}

.pb-3 {
  padding-bottom: @padding * 3;
}

.pb-4 {
  padding-bottom: @padding * 4;
}

.pb-5 {
  padding-bottom: @padding * 5;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: @padding * 1;
}

.pl-2 {
  padding-left: @padding * 2;
}

.pl-3 {
  padding-left: @padding * 3;
}

.pl-4 {
  padding-left: @padding * 4;
}

.pl-5 {
  padding-left: @padding * 5;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: @padding * 1;
}

.pt-2 {
  padding-top: @padding * 2;
}

.pt-3 {
  padding-top: @padding * 3;
}

.pt-4 {
  padding-top: @padding * 4;
}

.pt-5 {
  padding-top: @padding * 5;
}

.p-3 {
  padding: @padding * 3;
}
