.ehr-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  .ehr-table {
    &__filter {
      padding: 0 @padding-4 @padding-4;
      background-color: #ffffff;
    }

    &__wrapper {
      margin: @margin-2 @margin-2 0;
    }

    .ant-table-cell {
      white-space: nowrap;
    }
  }
}
