h4.bold {
  color: @text-color;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

h5.bold {
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
}

.h3-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.h4-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.h3-bold-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.h4-bold-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
}

.h4-medium-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.h5-semi-title {
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
}

.h6-regular-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

.text-grey {
  color: #595959;
}

.fs-12 {
  font-size: 12px;
}

label.default-label {
  color: #8c8c8c;
  font-size: 12px;
  line-height: 20px;
}
