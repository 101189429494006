.ant-input-group-lg .ant-input,
.ant-input-lg {
  font-size: @font-size-base;
}

.ant-input-lg {
  min-height: @height-lg;
}

textarea.ant-input {
  min-height: @height-lg;
  padding-top: @padding-1;
}

.ant-input-affix-wrapper {
  .ant-input-prefix {
    margin-right: 12px;
    color: @text-placeholder-color;
    font-size: 16px;
    line-height: 16px;
  }

  &-lg {
    min-height: @height-lg;
    font-size: @font-size-base;

    .ant-input {
      min-height: auto;
    }
  }
}
