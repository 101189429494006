.ehr-form-gap-status {
  &__opened {
    color: @red-base;
  }

  &__closed {
    color: @green-base;
  }

  &__overdue {
    color: @orange-base;
  }
}
