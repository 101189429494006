.ehr-user-sider {
  display: flex;
  flex-direction: column;
  height: 100%;

  &--collapsed {
    .ehr-user-sider__body {
      padding: @padding-2 12px;
    }
  }

  &__body {
    padding: @padding-2 @padding-3;
    flex: 1;
  }

  &__footer {
    position: sticky;
    bottom: 0;
    height: 36px;
    padding: @padding-1 @padding-4;
    border-top: 1px solid #ebebeb;
    background-color: @white-color;
  }
}

.ehr-user-sider__header {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: @padding-2 @padding-3;
  border-bottom: 1px solid @border-color-base;
  background-color: @white-color;

  &__collapse {
    display: flex;
    align-items: center;
    cursor: pointer;

    .ehr-text {
      margin-left: @margin-1;
    }
  }

  &__actions {
    padding: @padding-1 0;
    border-radius: 4px;
    background: @dark-primary-bg;

    li {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      color: rgba(255, 255, 255, 0.65);

      span {
        margin-right: @margin-1;
      }

      &:hover:not(.disabled) {
        color: @white-color;
        cursor: pointer;
      }

      &.disabled {
        cursor: not-allowed;
      }
    }
  }

  &--collapsed {
    flex-direction: column;
    justify-content: center;
    height: auto;
    padding: 0;
    border-bottom: 0;

    .ehr-user-sider__header__collapse {
      height: 56px;

      .ehr-text {
        display: none;
      }
    }
  }
}

.ehr-user-sider__collapse {
  display: block;
  margin-top: @margin-1;

  &__row {
    display: flex;
    margin-bottom: @margin-1;

    nz-avatar {
      position: relative;
      margin-right: 4px;
    }
  }

  .ant-collapse {
    border: none;
    background-color: @bg-light-color;

    &-item {
      border: none;
    }

    &-content {
      border-top: 0;
      background-color: inherit;

      &-box {
        padding-top: 0;
      }
    }

    &-header {
      display: flex;

      & > div {
        order: 2;
      }

      .ehr-user-sider__collapse__header {
        display: flex;
        flex: 1;
        align-items: center;
        order: 1;
        font-weight: 500;
        line-height: 22px;

        .anticon {
          font-size: @font-size-lg;
        }
      }
    }
  }
}

.ehr-user-sider__actions {
  display: flex;
  flex-wrap: wrap;
  gap: 12px 8px;
  margin: @margin-4 0;

  a,
  .crm-action-button {
    width: 100%;
  }

  &__invite.crm-action-button {
    margin-bottom: @margin-3;
  }

  &:not(&--collapsed):has(&__schedule-visit) {
    .ehr-user-sider__actions__schedule-visit {
      width: calc(50% - 4px);
    }

    .ehr-user-sider__actions__schedule-call {
      width: calc(50% - 4px);
    }
  }

  &:not(&--collapsed):has(&__go-to-messages) {
    .ehr-user-sider__actions__go-to-messages {
      width: calc(50% - 4px);
    }

    .ehr-user-sider__actions__send-email {
      width: calc(50% - 4px);
    }
  }

  &--collapsed {
    margin-top: @margin-3;

    .ant-btn {
      padding: @padding-1 12px;
    }

    .ehr-user-sider__actions__invite {
      margin-bottom: 0;
    }
  }
}

.ehr-user-sider__profile {
  display: flex;

  &__badge {
    .ant-badge {
      &-status {
        &-dot {
          position: absolute;
          z-index: 1;
          top: unset;
          right: 1px;
          bottom: 1px;
          width: 8px;
          height: 8px;
        }

        &-text {
          display: none;
        }
      }
    }
  }

  &__content {
    flex: 1;
    min-width: 0;
    margin-left: @margin-2;
  }

  &__status {
    display: inline-block;
    margin-top: 4px;
    padding: 2px 4px;
    border-radius: @border-radius-base;
    font-size: @font-size-sm;
  }
}
