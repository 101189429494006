.ant-btn:not(.ant-btn-text):not(.ant-btn-dashed):not(.ant-btn-primary):not(.ant-btn-link) {
  background-color: transparent;

  &:not([disabled]) {
    &:active,
    &:focus,
    &:hover {
      border-color: @text-color;
      color: @text-color;
    }
  }
}

.ant-btn-text {
  color: @primary-color;

  &:not([disabled]) {
    &:hover {
      color: @primary-color;
    }
  }
}

.ant-btn-dashed {
  border-color: @secondary-color;
  background-color: @secondary-color;
  color: @primary-color;

  &:not([disabled]) {
    &:active,
    &:focus,
    &:hover {
      border-color: @secondary-color;
      background-color: #ebe6f5;
    }
  }
}
