.ehr-dropdown-selector {
  display: flex;
  align-items: center;
  height: @height-lg;
  border: 1px solid @border-color-base;
  border-radius: @border-radius-base;
  cursor: pointer;

  &__placeholder {
    display: inline-flex;
    flex: 1;
    min-width: 0;
    padding-left: @padding-1;
    white-space: nowrap;
  }

  &__text {
    &.ehr-text--text {
      display: inline-flex;
      min-width: 0;

      .ant-typography {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    color: @text-placeholder-color;
    font-size: @font-size-lg;
  }

  &__dropdown {
    padding: @padding-1;
    border-radius: @border-radius-base;
    background-color: @white-color;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);

    .ant-form-item {
      margin-bottom: @margin-2;
    }
  }
}
