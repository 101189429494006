.ehr-copyable {
  display: block;
  max-width: 100%;

  &__content {
    display: flex;
    align-items: center;
  }

  &__value {
    display: block;
    width: 100%;
    padding-right: @padding-2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
