@select-item-selected-bg: @secondary-color;

.ant-select-dropdown {
  padding: @padding-1;
  margin-top: @margin-1;
}

.ant-select-item {
  align-items: center;
  padding: @padding-1 @padding-2;
  border-radius: @border-radius-base;

  &-option-selected:not(.ant-select-item-option-disabled) {
    background-color: @secondary-color;
    color: @primary-color;
    font-weight: 400;
  }

  &:hover:not(&-option-selected) {
    background-color: @bg-light-color;
    font-weight: 400;
  }
}

.ant-select-lg {
  font-size: @font-size-base;
}

.ant-select-arrow {
  font-size: @font-size-lg;
}
