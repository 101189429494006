.ehr-table {
  display: flex;
  flex-direction: column;
  min-height: 0;
  max-height: 100%;

  &__wrapper {
    display: flex;
    flex-direction: column;
    min-height: 0;
    margin-top: @margin-2;
    padding-bottom: @padding-3;

    &--has-title {
      padding: @padding-3 @padding-4;
      background-color: #ffffff;

      .ehr-table {
        flex: none;
      }

      .ant-table-wrapper {
        padding: @padding-2 0 0 0;
      }
    }

    .ant-table-wrapper {
      display: flex;
      min-height: 0;
      overflow: hidden;
    }
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    display: flex;
    align-items: center;
    flex: 1;

    .anticon {
      font-size: 20px;
      margin-right: @margin-1;
    }
  }

  &__filter {
    display: block;

    &--after-title {
      margin-top: @margin-2;
    }
  }

  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table-container,
  .ant-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
  }

  .ant-table-container {
    overflow: auto;
  }

  .ant-table-bordered {
    .ehr-table__row {
      &--right-sticky {
        &::before {
          position: absolute;
          top: 0;
          left: -1px;
          width: 1px;
          height: 100%;
          background-color: @bg-light-color-2;
          content: '';
        }
      }
    }
  }

  .ant-table-thead,
  .ant-table-tbody {
    .ehr-table__row {
      cursor: pointer;

      &--right-sticky {
        position: sticky;
        z-index: 2;
        right: 0;

        &:not(th) {
          background-color: #ffffff;
        }
      }

      &--left-sticky {
        position: sticky;
        z-index: 2;
        left: 0;

        &:not(th) {
          background-color: #ffffff;
        }
      }

      &__cell-checkbox {
        width: 48px;
      }

      &--selected {
        .ant-table-cell {
          background-color: @secondary-color;
        }

        .ehr-table__row--right-sticky,
        .ehr-table__row--left-sticky {
          background-color: @secondary-color;
        }
      }

      &--expanded {
        .ehr-table__row--expand i {
          transform: rotate(90deg);
        }
      }

      &--expanded-content:hover .ant-table-cell {
        background-color: inherit;
      }

      .ant-table-cell {
        padding: 12px @padding-2;
        line-height: 24px;
      }
    }

    .ehr-table__cell {
      &--text-icon {
        .anticon {
          margin-left: @margin-1;
        }
      }
    }
  }

  .ant-table-thead {
    position: sticky;
    z-index: 3;
    top: 0;
  }
}
