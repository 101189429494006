@import './divider';

.modal {
  &--with-simple-dividers {
    .ant-divider {
      .simple-divider-horizontal;
    }
  }

  &--with-no-bottom-padding {
    .ant-modal-body {
      padding-bottom: 0;
    }
  }
}
