.ehr-upload {
  .ant-upload-drag .ant-upload {
    padding: @padding-5 0;

    &:hover {
      background-color: @secondary-color;
    }
  }

  &--full {
    .ant-upload {
      display: none;
    }

    .ant-upload-list-item-list-type-picture {
      margin-top: 0;
    }
  }

  &.ng-invalid.ng-dirty {
    .ant-upload-drag {
      border-color: @error-color;
    }
  }

  &__body {
    &__icon {
      margin-bottom: @margin-2;

      .anticon {
        color: @text-placeholder-color;
        font-size: 48px;
      }
    }

    &--drag {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.drag-upload {
  margin: -@margin-2 0;
  padding: @padding-4;

  &:hover {
    background-color: @secondary-color;
  }
}
