.ehr-schedule-visit {
  &-wrapper {
    display: flex;
    top: 96px;
    right: 48px;
    bottom: unset;
    left: unset;
    max-height: calc(100% - 128px);
    overflow: hidden;

    .ant-modal {
      top: 0;
      margin: 0;
      padding-bottom: 0;

      &-content {
        display: flex;
        flex-direction: column;
        max-height: 100%;
        box-shadow: none;
      }

      &-body {
        flex: 1;
        min-height: 0;
        overflow: auto;
      }
    }
  }

  &__information {
    display: flex;
    padding-top: @padding-2;

    &-icon {
      position: relative;
      top: 4px;
      margin-right: @margin-1;
    }
  }
}

.form-body--call-log.form-body--schedule-visit-visible {
  @media (max-width: 1700px) {
    max-width: calc(100% - 380px - @margin-4 * 2 - @padding-4);
    margin: @margin-4;
  }
}
