@picker-basic-cell-active-with-range-color: @secondary-color;
@picker-date-hover-range-color: darken(@picker-basic-cell-active-with-range-color, 10%);

.ant-picker-large .ant-picker-input > input {
  font-size: @font-size-base;
}

.ant-picker-large {
  min-height: @height-lg;
}

.ant-picker-cell-in-view {
  &.ant-picker-cell-range-start,
  &.ant-picker-cell-range-end {
    .ant-picker-cell-inner {
      background-color: @picker-basic-cell-active-with-range-color;
      border: 1px solid @primary-color;
      border-radius: 0;
      color: @primary-color;
    }
  }

  &.ant-picker-cell-range-start {
    &::before {
      left: 0;
    }

    &:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) {
      .ant-picker-cell-inner {
        border-radius: 0;
      }
    }
  }

  &.ant-picker-cell-range-end {
    &::before {
      right: 0;
    }

    &:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-start) {
      .ant-picker-cell-inner {
        border-radius: 0;
      }
    }
  }
}

.ant-picker-cell::before {
  top: 50%;
  height: 100%;
}
