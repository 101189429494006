.ehr-title {
  display: block;
  flex: 1;

  &--weight {
    &-medium {
      h4,
      h5 {
        font-weight: 500;
      }
    }

    &-bold {
      h1,
      h2,
      h4 {
        font-weight: 700;
      }

      h3 {
        font-weight: 500;
      }
    }
  }

  &--margin {
    &-0 {
      .ant-typography {
        margin-bottom: 0;
      }
    }

    &-1 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: @margin-1;
      }
    }

    &-2 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: @margin-2;
      }
    }

    &-3 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: @margin-3;
      }
    }

    &-4 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: @margin-4;
      }
    }

    &-8 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: @margin-8;
      }
    }
  }

  h1 {
    font-size: 30px;
    line-height: 38px;
  }

  h2 {
    font-size: 24px;
    line-height: 32px;
  }

  h3 {
    font-size: 20px;
    line-height: 28px;
  }

  h4 {
    font-size: @font-size-lg;
    line-height: 24px;
  }

  h5 {
    font-size: @font-size-sm;
    line-height: 24px;
  }

  h6 {
    color: @heading-color;
  }
}
