@crm-layout-collapse-border-dark: none;
@crm-layout-collapse-color-dark: @text-secondary-color;

@crm-layout-logo-height: 104px;

@crm-user-profile-name-color: @white-color;
@crm-user-profile-items-bg-dark: @dark-primary-bg;
@crm-user-profile-items-bg-dark-hover: @dark-primary-bg;

.crm-user-profile {
  &-content-name {
    overflow: auto;
    white-space: normal;
  }

  &-dropdown-icon {
    color: @white-color;
  }

  &-items {
    padding: 0;
    border-top-right-radius: @border-radius-base;
    border-bottom-right-radius: @border-radius-base;
  }

  &-item {
    margin: 0;
    transition: none;
  }
}

.crm-layout-sider-left {
  .crm-layout-fragment-menu {
    .ant-menu-dark {
      .ant-menu-item {
        color: @text-secondary-color;

        &:hover, &.ant-menu-item-selected {
          color: #ffffff;
        }
      }

      .ant-menu-inline.ant-menu-sub {
        background: @dark-primary-bg;
      }
    }
  }
}

.crm-layout-sider-right {
  border-left: 1px solid #e8e8e8;
  box-sizing: content-box;

  .crm-layout-fragment-right-sidebar {
    flex: 1;
    height: 100%;
  }

  .ant-layout-sider-children {
    border-right: 0;
  }
}
