.app-card {
  padding: 16px;
  border-radius: 8px;
  background-color: #ffffff;

  &-np {
    padding: 0;
  }

  &-lg {
    padding: 32px;
  }

  &-title {
    font-size: 20px;
    font-weight: 700;
  }
}
