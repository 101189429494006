.ehr-calendar-sider {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__collapse {
    padding: @padding-2 @padding-3;
    line-height: 22px;
    cursor: pointer;

    .ehr-text {
      margin-left: @margin-3;
    }
  }

  &__picker {
    .ant-picker-panel-container {
      box-shadow: none;

      .ant-picker-panel {
        border: 0;
      }

      .ant-picker-header {
        border: 0;
      }

      .ant-picker-date-panel,
      .ant-picker-week-panel {
        width: auto;
      }
    }
  }

  &__resources {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 0;
    padding: @padding-2 @padding-2 0;

    .ehr-title {
      flex: 0;
    }

    .ant-checkbox-group {
      flex: 1;
      min-height: 0;
      margin-top: @margin-2;
      padding-right: @padding-2;
      padding-bottom: @padding-2;
      overflow: auto;
    }

    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      margin-left: 0;

      .ant-checkbox {
        top: 0;
        order: 2;

        + span {
          display: flex;
          flex: 1;
          align-items: center;
          padding-left: 0;
        }
      }

      &::after {
        content: none;
      }

      &:not(:last-child) {
        margin-bottom: @margin-2;
      }
    }
  }

  &__resource-name {
    flex: 1;
    min-width: 0;
    margin-left: @margin-1;
  }
}
