.ehr-time {
  display: block;
  position: relative;

  &__zone-abbr {
    position: absolute;
    height: 100%;
    padding: @padding-1;
    top: 0;
    left: 72px;
    font-size: @font-size-base;
    pointer-events: none;
  }
}
