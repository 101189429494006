.ehr-form-checkbox,
.ehr-form-radio {
  &__read {
    &__item {
      margin-bottom: @margin-1;

      &--right {
        display: flex;
        align-items: center;

        .ehr-form-checkbox,
        .ehr-form-radio {
          &__read__control {
            flex: 1;
            min-width: 0;
            margin-top: 0;
            padding-left: @padding-1;
          }
        }
      }
    }

    &__control {
      margin-top: @margin-1;
      padding-left: @padding-3;
    }
  }
}

.ehr-form-checkbox__read {
  &__message::before {
    display: inline-block;
    position: relative;
    content: '-';
  }

  &--inline {
    display: flex;

    .ehr-form-checkbox__read {
      &__item:not(:last-child) {
        .ehr-form-checkbox__read__message::after {
          display: inline-block;
          position: relative;
          margin-right: 4px;
          margin-left: -4px;
          content: ',';
        }
      }

      &__item:not(:first-child) {
        .ehr-form-checkbox__read__message::before {
          content: none;
        }
      }
    }
  }
}
