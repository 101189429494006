.ehr-action-sidebar {
  display: flex;
  height: 100%;

  &__menu {
    width: 64px;
    border-right: 1px solid #e8e8e8;

    &-item {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      height: 56px;
      color: #595959;
      cursor: pointer;

      &:hover,
      &.selected {
        color: #4211a0;
      }

      &:hover::before,
      &.selected::before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background-color: #4211a0;
        content: '';
      }
    }
  }

  &__content {
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    min-width: 0;
    height: 100%;
    padding: 16px;

    &-title {
      padding: @padding-2;
    }
  }

  .crm-loading {
    margin: auto;
  }
}
