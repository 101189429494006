.ehr-best-time-to-call {
  &__edit {
    margin-bottom: @margin-3;
  }

  &__days {
    display: flex;
    flex-flow: wrap;

    .ehr-title {
      text-transform: uppercase;
    }
  }

  &__day {
    margin-right: @margin-4;

    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      margin-left: @margin-1;
    }

    .ant-checkbox-wrapper {
      margin-left: 0;
      padding: 6px 0;
      font-size: @font-size-sm;
      line-height: 24px;
    }
  }
}
