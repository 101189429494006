@margin: 8px;

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: @margin * 1;
}

.mr-2 {
  margin-right: @margin * 2;
}

.mr-3 {
  margin-right: @margin * 3;
}

.mr-4 {
  margin-right: @margin * 4;
}

.mr-5 {
  margin-right: @margin * 5;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: @margin * 1;
}

.mb-2 {
  margin-bottom: @margin * 2;
}

.mb-3 {
  margin-bottom: @margin * 3;
}

.mb-4 {
  margin-bottom: @margin * 4;
}

.mb-5 {
  margin-bottom: @margin * 5;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: @margin * 1;
}

.ml-2 {
  margin-left: @margin * 2;
}

.ml-3 {
  margin-left: @margin * 3;
}

.ml-4 {
  margin-left: @margin * 4;
}

.ml-5 {
  margin-left: @margin * 5;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: @margin * 1;
}

.mt-2 {
  margin-top: @margin * 2;
}

.mt-3 {
  margin-top: @margin * 3;
}

.mt-4 {
  margin-top: @margin * 4;
}

.mt-5 {
  margin-top: @margin * 5;
}
